import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Button from '../shared/Button';
import ExerciseProgress from './ExerciseProgress';
import UserHelper from '../helpers/UserHelper';
import ApiHelper from '../helpers/ApiHelper';
import DataHelper from '../helpers/DataHelper';
import './Evaluation.scss';

class ExerciseEvaluation extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            user: null,
            type: this.props.match.params.type,
            workout_uuid: this.props.match.params.workout_uuid,
            program: null,
            exercise_index: -1,
            selection: 0
        }

        this.config = null;
        this.idle_timeout = null;
    }

    componentWillUnmount() {
        // Clear timeout interval
        if (this.idle_timeout) {
            clearInterval(this.idle_timeout);
        }
    }

    async componentDidMount() {

        // Get config
        await DataHelper.get('config').then(config => {
            this.config = config;
        });

        // Set index and user name to state
        let index = localStorage.getItem('current_exercise_index');
        if (index) {
            index = parseInt(index);
        } else {
            index = 0;
        }

        // Remove current exercise from index if this is workout evaluation
        // (it is finished)
        if (this.state.type === 'workout') {
            localStorage.removeItem('current_exercise_index');
        }

        // Get user
        await UserHelper.getUser().then(user => {
            this.setState({
                user: user,
                exercise_index: index
            });
        });

        // Get all exercises and set Button caption for continuing
        await UserHelper.getUserProgram().then(program => {
            this.setState({ program: program });
        });

        // Set idle timeout
        // Go to dashboard
        this.idle_timeout = setTimeout(() => {
            this.history.push('/dashboard');
        }, this.config.idle_timeout_minutes * 60000);
    }

    async next() {
        let index = this.state.exercise_index;

        // Send evaluation to API
        ApiHelper.Put('/workouts/' + this.state.workout_uuid, {
            type: this.state.type,
            evaluation: this.state.selection
        });

        // Reset
        this.setState({ selection: 0 });

        // If not last exercise, go to next
        if (this.state.type === 'exercise' && index < this.state.program.exercises.length - 1) {
            // Increase index
            localStorage.setItem('current_exercise_index', index + 1);
            // Go
            this.history.push('/exercise');
        }
        // Else go to workout evaluation if on exercise
        else if (this.state.type === 'exercise' && this.state.user.meta.evaluation === 'after_workout') {
            this.history.push('/evaluation/workout/' + this.state.workout_uuid);
            this.setState({ type: 'workout'});
        }
        // Else go to voice messaging if set in config
        else if (this.state.user.meta.voice_messages === true) {
            localStorage.removeItem('current_exercise_index');
            this.history.push('/voice');
        }
        // Else save workout and go to dashboard
        else {
            // Save workout finished
            localStorage.setItem('workout_finished', new Date());
            localStorage.removeItem('workout_uuid');
            localStorage.removeItem('program_uuid');
            localStorage.removeItem('current_exercise_index');
            localStorage.removeItem('current_set');
            
            this.history.push('/dashboard');
        }
    }

    render() {

        const { t } = this.props;

        return (
            <SkeletonTheme color="#eecbc5" highlightColor="#eed9d6">
                <section id="view-exercise_evaluation" className="white">
                    <div className="header">

                        <ExerciseProgress
                            program={this.state.program}
                            currentIndex={this.state.exercise_index}
                            progress={1}
                            evaluation={this.state.type}
                            parent={this} />

                        { this.state.type === 'exercise' ? (
                            <h1>{
                                this.state.user ?
                                    <>
                                        { t('evaluation:exercise_heading', { name: this.state.user.first_name }) }
                                        <br />
                                        { t('evaluation:exercise_done') }
                                    </>
                                : <Skeleton /> }
                            </h1>
                        ) : (
                            <h1>{
                                this.state.user ?
                                    <>
                                        { t('evaluation:workout_heading', { name: this.state.user.first_name }) }
                                        <br />
                                        { t('evaluation:workout_done') }
                                    </>
                                : <Skeleton /> }
                            </h1>
                        )}
                    </div>
                    { this.state.type === 'exercise' ? (
                        <h2>{ t('evaluation:exercise_question') }</h2>
                    ) : (
                        <h2>{ t('evaluation:workout_question') }</h2>
                    )}                
                    <div className="exercise-survey">
                        <Button className={'exercise-survey-choice choice-1' + (this.state.selection === 1 ? ' selected' : '')} onClick={() => this.setState({ selection: 1 })}>{ t('evaluation:responses.0') }</Button>
                        <Button className={'exercise-survey-choice choice-2' + (this.state.selection === 2 ? ' selected' : '')} onClick={() => this.setState({ selection: 2 })}>{ t('evaluation:responses.1') }</Button>
                        <Button className={'exercise-survey-choice choice-3' + (this.state.selection === 3 ? ' selected' : '')} onClick={() => this.setState({ selection: 3 })}>{ t('evaluation:responses.2') }</Button>
                        <Button className={'exercise-survey-choice choice-4' + (this.state.selection === 4 ? ' selected' : '')} onClick={() => this.setState({ selection: 4 })}>{ t('evaluation:responses.3') }</Button>
                        <Button className={'exercise-survey-choice choice-5' + (this.state.selection === 5 ? ' selected' : '')} onClick={() => this.setState({ selection: 5 })}>{ t('evaluation:responses.4') }</Button>
                    </div>
                    { this.state.selection > 0 &&
                        <div className="button-bar bottom">
                            <Button className="blue-dark" Icon="arrow-right" onClick={() => this.next() }>{(this.state.type === 'exercise' && (this.state.program && this.state.program.exercises.length > (this.state.exercise_index + 1)) ? t('evaluation:exercise_continue') : t('evaluation:workout_continue'))}</Button>      
                        </div>
                    }
            </section>
          </SkeletonTheme>
        );
    }
}

export default withRouter(withTranslation(['evaluation', 'common'])(ExerciseEvaluation));