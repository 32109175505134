import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SystemHelper from './helpers/SystemHelper';
import FileHelper from './helpers/FileHelper';
import UserHelper from './helpers/UserHelper';
import Button from './shared/Button';
import './Onboarding.scss';

class Onboarding extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
    }

    skip() {
        this.history.push('/preload');
    }

    componentDidMount() {
        UserHelper.getUser().then((user) => {
            // Set onboarding finished
            localStorage.setItem('onboarding_finished_' + user.uuid, true);
        });

        const locale = localStorage.getItem('locale') ?? 'en';
        let video_pref = FileHelper.getPlatformVideoPreferences();
        let path = video_pref.extension === 'webm' ? '/assets/video/onboarding_' + locale + '.webm' : '/assets/video/onboarding.' + video_pref.extension;
        let file = SystemHelper.getSystemPath(path);
        let video = document.getElementById('onboarding-video');
        video.src = file;
        video.addEventListener('ended', () => {
            this.history.push('/preload');
        });
        video.addEventListener('error', () => {
            //alert('Filmen funkar inte. Skickar vidare...');
            this.history.push('/preload');
        });
        video.play();

        // Browser fallback on no interaction
        video.addEventListener('click', () => {
            video.play();
        });
    }

    render() {

        const { t } = this.props;

        return (
            <section id="view-onboarding">
                <video id="onboarding-video" playsInline />
                <div className="button-bar bottom">
                        <Button className="center blue-dark auto-width" Icon="arrow-right" onClick={() => this.skip()}>{ t('common:skip') }</Button>
                    </div>
            </section>
        );
    }
}

export default withRouter(withTranslation(['common'])(Onboarding));