import axios from 'axios';
import i18next from 'i18next';
import ApiHelper from './ApiHelper';
import SystemHelper from './SystemHelper';
import PushHelper from './PushHelper';

const UserHelper = {

    authenticated: false,

    /**
     * Login user for the first time
     */
    login: (code, admin_token) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/login', { code: code, admin_token: admin_token }).then(res => {
                console.log(res);
                resolve(res);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    },

    /**
     * Request validation token for admin
     */
    requestStaffCode: (code) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/send-staff-code', { code: code }).then(res => {
                resolve(res);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Verify validation token for admin
     */
    verifyStaffCode: (code, target_code) => {
        return new Promise((resolve, reject) => {
            axios.post(ApiHelper.LoginHost + '/api/verify-staff-code', { code: code, target_code: target_code }).then(res => {
                console.log(res);
                resolve(res);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    },

    /**
     * Get logged in user
     */
    getUser() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/user').then(user => {

                // Set locale each time if set for user
                if (user.data.data.meta.locale) {
                    localStorage.setItem('locale', user.data.data.meta.locale);
                    i18next.changeLanguage(user.data.data.meta.locale);
                } else if (localStorage.getItem('locale') === 'undefined') {
                    localStorage.removeItem('locale');
                }

                resolve(user.data.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    /**
     * Logout user
     * @param {Component instance for redirecting to login} component
     */
    async logout(component) {

        // Remove device id if exists
        if (localStorage.getItem('device_id')) {
            await PushHelper.removeDevice(localStorage.getItem('device_id'));
        }

        // Logout backend
        //ApiHelper.Post('/logout')

        // Logout admin from device
        UserHelper.logoutAdmin();

        // Remove locale storage objects
        localStorage.removeItem('token');
        localStorage.removeItem('host');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('last_workout');
        localStorage.removeItem('current_exercise_index');
        localStorage.removeItem('locale');
        localStorage.removeItem('video_setting');

        console.log('Removed all local storage items');

        // Send to login if component sent
        if (component) {
            window.location.hash = '#/login';
        }
    },

    /**
     * Logout current admin
     */
    logoutAdmin() {
        localStorage.removeItem("admin_token");
		localStorage.removeItem('host');
    },

    token: {
        set: (token) => {
            localStorage.setItem('token', token);
        },
        get: () => {
            return localStorage.getItem('token');
        }
    },

    code: {
        set: (code) => {
            localStorage.setItem('user_id', code);
        },
        get: () => {
            return localStorage.getItem('user_id');
        }
    },

    name: {
        set: (name) => {
            localStorage.setItem('user_name', name);
        },
        get: () => {
            return localStorage.getItem('user_name');
        }
    },

    /**
     * Returns programs that run today
     * @param {All user's programs} all_programs
     */
    getTodaysPrograms(all_programs) {
        const now = new Date();
        const day_of_week = SystemHelper.dayOfWeek(now.getDay());
        let programs = all_programs.data.filter((x) => { return (x.meta.days.indexOf(day_of_week) > -1) });
        return programs;
    },

    /**
     * Checks if program is active due to start and end date
     * @param {The program to check} program 
     * @returns Bool indicating if program is active
     */
    isProgramActive(program) {
        let now = new Date().toISOString().substring(0, 10);
        let starts_at = new Date(program.starts_at);
        let ends_at = new Date(program.ends_at);

        // Only use the date part
        now = new Date(now);

        return (
            (now >= starts_at || !program.starts_at) && 
            (now <= ends_at || !program.ends_at)
        );
    },

    /**
     * Get current active program for user
     */
    getUserProgram() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/program-active').then(program => {
                let active_program = program.data;
                if (active_program && active_program.length === 0) {
                    active_program = null;
                    console.log('No active program');
                }
                resolve(active_program);

            }).catch(error => {
                //alert(error);
                reject(error);
            });
        });
    },

    /**
     * Get the next reminder
     */
    getNextReminder() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/reminders/next').then(data => {
                console.log('remmdmdmd', data);
                resolve(data.data.reminder);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default UserHelper;
