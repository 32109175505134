import React, { useEffect, useState } from 'react';
import FileHelper from '../helpers/FileHelper';
import { WebVTTParser } from 'webvtt-parser';

const SubtitlePlayer = ({ videoElement, subtitleSrc }) => {
  const [subtitles, setSubtitles] = useState([]);
  const [displayText, setDisplayText] = useState('');
  const [hidden, setHidden] = useState(true);
  

  useEffect(() => {
    console.log('Getting subtitles', subtitleSrc);

    // Get subtitles from file system
    FileHelper.get(subtitleSrc).then(fileEntry => {
        fileEntry.file(file => {
            if (!file) {
                return;
            }
            const reader = new FileReader();
            reader.onloadend = (event) => {
                const vttData = event.target.result;
                const parser = new WebVTTParser();
                const tree = parser.parse(vttData, 'metadata');
            
                // Set cues in state
                setSubtitles(tree.cues);
            };
            reader.onerror = (error) => {
                console.log('error', error);
            };
            reader.readAsText(file);
        });

    });

  }, [subtitleSrc]);

  useEffect(() => {
    console.log('videoElement', videoElement);

    if (videoElement) {
        // Update subtitles on video time update
        videoElement.addEventListener('timeupdate', () => {
            const currentTime = videoElement.currentTime;

            // Cues are empty some times, don't know why
            if (subtitles.length === 0) {
                return;
            }

            // Find the active cure
            const activeCue = subtitles.find(
                (cue) => currentTime < cue.endTime && currentTime < videoElement.duration
            );

            if (activeCue?.text) {
                setDisplayText(activeCue.text);
                setHidden(false);
            } else {
                // Hide subtitles if no active cue
                setHidden(true);
            }
      });
    } else {
        console.log('No video element');
    }
  }, [subtitles, videoElement]);

  return <div className={ 'video-subtitles' + (hidden ? ' hidden' : '') }><p>{ displayText }</p></div>;
};

export default SubtitlePlayer;
