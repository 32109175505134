import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Button from '../shared/Button';
import ApiHelper from '../helpers/ApiHelper';
import UserHelper from '../helpers/UserHelper';
import InstallationHelper from '../helpers/InstallationHelper';
import DataHelper from '../helpers/DataHelper';
import CameraHelper from '../helpers/CameraHelper';
import ExerciseVideo from './ExerciseVideo';
import ExerciseProgress from './ExerciseProgress';
import ExercisePaused from './ExercisePaused';
import AudioHelper from '../helpers/AudioHelper';
import MusicHelper from '../helpers/MusicHelper';
import SystemHelper from '../helpers/SystemHelper';
import PoseDetector from './pose-detection/PoseDetector';
import './Exercise.scss';

class Exercise extends Component {

    constructor(props) {
        super(props);
        this.isInstallation = localStorage.getItem('admin_token') === 'installation';
        this.history = this.props.history;
        this.state = {
            user:null,
            config: {},
            program: this.props.location.state ? (this.props.location.state.program ?? null) : null,
            workout_uuid: localStorage.getItem('workout_uuid'),
            exercises: [],
            current_exercise_index: 0,
            current_set: 1,
            progress: 0,
            paused: false,
            countdown_time: -1,
            show_exercise_countdown: true,
            show_set_countdown: false,
            camera_ready: false,
            use_native_camera: true,
            is_paused: false,
            manually_paused: false,
            rep_num: 0,
            use_pose_detection: false,
            user_is_on_camera: false,
            loading_pose_detection: false,
        }
        
        this.exercise = {
            current_exercise: null,
            interval: null,
            countdown_interval: null,
            repetition_delay_interval: null,
            repetition_delay_time: 0,
        }

        this.pose_detection_started = false;

        this.exercise_video = React.createRef();

        // Music
        this.music = null;

        // Pose detector
        this.pose_detector = null;

        // Finished audio
        this.finished_audio = null;
        this.FINISHED_AUDIO_VOLUME_RATIO = SystemHelper.isIos() ? 0.15 : 0.25;
    }

    async loadUser() {
        await UserHelper.getUser().then(async (user) =>{
            if (!user.meta.allow_modify_instructor) {
                // Reset styles if transform disabled
                this.exercise_video.current.video_transform.current.resetStyles();
            }
            await this.setState({ user: user });
        });
    }

    async loadInstallation(){
        await InstallationHelper.getInstallation().then((installation) =>{
            this.setState({ installation: installation });
        });
    }

    /**
     * Init the current exercise
     */
    async init() {
        const component = this;

        // Pause  exercise if app is paused/deactivated
        if (window.cordova) {
            document.addEventListener('pause', () => this.pause(true), false);
        }

        // Get config
        await DataHelper.get('config').then((config) => {
            this.config = config;
            this.setState({ config: config });
        });
        
        if (this.isInstallation) {
            await this.loadInstallation();
        } else {
            await this.loadUser();
        }

        // Init pose detector if activated for user
        if (this.state.user?.meta.require_presence) {
            this.pose_detector = new PoseDetector();
            this.setState({ use_pose_detection: true });
        }

        // Check local storage for current exercise
        let index = localStorage.getItem('current_exercise_index');
        if (index) {
            if (parseInt(index) < 0) {
                index = 0;
            }
            await this.setState({ current_exercise_index: parseInt(index) });
            localStorage.setItem('current_exercise_index', parseInt(index));
        } else {
            localStorage.setItem('current_exercise_index', 0);
        } 

        // Set date for last workout in storage
        localStorage.setItem('last_workout', new Date());

        // Get user's program
        if (this.state.program != null) {
            let exercises = this.state.program.exercises;

            // Set all exercises in state and set current exercise
            await component.setState({
                program: this.state.program,
                exercises: this.state.program.exercises,
                exercise_countdown: this.state.program.meta?.countdown_before_exercise ?? 5,
                exercise_countdown_first: this.state.program.meta?.countdown_before_exercise ?? 5, // Must be added to meta
                set_countdown: this.state.program.meta?.pause_between_sets ?? 5
            });

            // Create new workout if no existing
            // TODO: Should be moved to helper
            if (!this.isInstallation && !this.state.workout_uuid) {
                // Register workout start
                ApiHelper.Post('/workouts', { program_uuid: this.state.program.uuid }).then((workout_uuid) => {
                    // This is weird!!?
                    if (workout_uuid.data) {
                        workout_uuid = workout_uuid.data;
                    }
                    if (workout_uuid.data) {
                        workout_uuid = workout_uuid.data;
                    }

                    component.setState({ workout_uuid: workout_uuid });
                    // Save workout and program uuid in local storage
                    localStorage.setItem('workout_uuid', workout_uuid);
                    localStorage.setItem('program_uuid', this.state.program.uuid);
                });     
            }

            if (component.state.current_exercise_index >= exercises.length) {
                component.setState({ current_exercise_index: 0 });
            }
            // Set current exercise
            component.exercise.current_exercise = exercises[component.state.current_exercise_index];

            // Set styles
            this.exercise_video.current.video_transform.current.setStyles();

            console.log('current_exercise', component.exercise.current_exercise);
        } else {
            await UserHelper.getUserProgram().then(async (program) => {
                let exercises = program.exercises;

                // Check countdown
                let countdown = program.meta.countdown_before_exercise;
                if (!countdown) {
                    console.error('Countdown is not set! Setting 5 s...');
                    countdown = 5;
                }

                // Set all exercises in state and set current exercise
                await component.setState({
                    program: program,
                    exercises: program.exercises,
                    exercise_countdown: countdown,
                    exercise_countdown_first: countdown,                // Must be added to meta
                    set_countdown: program.meta.pause_between_sets
                });

                // Create new workout if no existing
                // TODO: Should be moved to helper
                if (!this.state.workout_uuid) {
                    // Register workout start
                    ApiHelper.Post('/workouts', { program_uuid: program.uuid }).then((workout_uuid) => {
                        // This is weird!!?
                        if (workout_uuid.data) {
                            workout_uuid = workout_uuid.data;
                        }
                        if (workout_uuid.data) {
                            workout_uuid = workout_uuid.data;
                        }

                        component.setState({ workout_uuid: workout_uuid });
                        // Save workout and program uuid in local storage
                        localStorage.setItem('workout_uuid', workout_uuid);
                        localStorage.setItem('program_uuid', this.state.program.uuid);
                    });     
                }

                if (component.state.current_exercise_index >= exercises.length) {
                    component.setState({ current_exercise_index: 0 });
                }

                // Set current exercise
                component.exercise.current_exercise = exercises[component.state.current_exercise_index];

                // Set styles
                this.exercise_video.current.video_transform.current.setStyles();

                console.log('current_exercise', component.exercise.current_exercise);

            }).catch(error => {
                console.error(error);
                // Go back to dashboard if no program
                this.history.push('/dashboard');
            });
        }

        // Abort if no program or exercise
        if (!this.state.program || !component.exercise_video.current) {
            return;
        }

        // Load exercise
        component.exercise_video.current.load(component.exercise.current_exercise).then(async () => {
            // Reset repetition number
            this.setState({ rep_num: 0 });

            // Start mirror camera if available
            if (CameraHelper.cameraAvailable()) {
                // Set crop size right for mirror
                component.setMirrorSize();
                // Start camera
                // Pass true if pose detector is inited
                await component.startCamera(this.pose_detector ? true : false).then(async () => {
                    console.log('Camera resolved');

                    // Init pose detection if active and not already started
                    if (this.pose_detector && !this.pose_detection_started) {
                        this.pose_detection_started = true;
                        this.setState({ loading_pose_detection : true });

                        // Use video feed if in browser
                        // Otherwise pass null
                        await component.pose_detector.init(component, this.state.use_native_camera ? null : document.getElementById('mirror'));
                        
                        this.setState({ loading_pose_detection : false });
                    }
                }).catch(err => {
                    console.log('Camera error', err);
                });
            }
            // Start countdown
            component.countdown('exercise');

        }).catch(error => {
            console.log('error', error);
            console.log('Go to preload, exercise index is', localStorage.getItem('current_exercise_index'));
            
            // Go to preload and pass current program
            this.history.push({
                pathname: '/preload',
                state: {
                    program: this.state.program
                }
            });
        });
    }

    /**
     * Start countdown before exercise starts
     * or between
     */
    async countdown(type) {
        const component = this;

        // Reset and show countdown
        if (type === 'exercise') {
            await component.setState({ countdown_time: this.state.exercise_countdown });
            await component.setState({ show_exercise_countdown: true });
        } else {
            await component.setState({ countdown_time: this.state.set_countdown });
            await component.setState({ show_set_countdown: true });
        }

        console.log('countdown time is', this.state.countdown_time);

        // Clear previous countdown if exists
        if (component.exercise.countdown_interval) {
            clearInterval(component.exercise.countdown_interval);
        }

        // Start countdown
        component.exercise.countdown_interval = setInterval(async () => {
            let time = this.state.countdown_time;
            await component.setState({ countdown_time: '' });
            await component.setState({ countdown_time: (time - 1) });
            // When countdown is finished
            if (component.state.countdown_time === 0) {
                clearInterval(component.exercise.countdown_interval);
                if (type === 'exercise') {
                    component.setState({ show_exercise_countdown: false });
                } else {
                    component.setState({ show_set_countdown: false });
                }
                component.start();
            }
        }, 1000);
    }

    /**
     * Start the exercise
     */
    async start() {
        const component = this;
        // Set start values
        this.exercise.progress = 0;
        this.setState({ rep_num: 0 });

        // Start video
        this.exercise_video.current.play().then(() => {
            // Run done() when exercise finished
            component.done();
        });

        // Stop interval if exists
        if (this.exercise.interval) {
            clearInterval(this.exercise.interval);
        }

        console.log('Starting progress interval');

        // Increase progress
        this.exercise.interval = setInterval(() => {
            if (!this.state.is_paused) {
                component.setState({ progress: component.getProgress() });
            }
        }, 60);
    }

    /**
     * Get current exercise progress in %
     */
    getProgress() {
        let progress = 0;
        let video_time = 0;
        if (!this.state.show_countdown) {
            video_time = this.exercise_video.current.getCurrentTime();
            progress = video_time / this.exercise_video.current.video.total_duration;
        }
        //console.log('video_time: ' + video_time + ', progress: ' + progress + ', total_duration: ' + this.exercise_video.current.video.total_duration);
        return progress;
    }

    /**
     * Set size of camera mirror
     */
    setMirrorSize() {
        const mirror = document.querySelector('#mirror');
        mirror.width = mirror.offsetWidth;
        mirror.height = mirror.offsetHeight;
    }

    /**
     * Start the camera
     */
    async startCamera(use_pose_detection) {
        console.log('Start camera...');
        return new Promise((resolve, reject) => {
            // Check if camera is already ready
            if (this.state.camera_ready) {
                resolve();
            } else {
                // Start the camera
                CameraHelper.startCamera(use_pose_detection).then(() => {

                    console.log('Camera started, pose detection:', use_pose_detection);

                    // Set camera settings in state
                    this.setState({
                        camera_ready: true,
                        use_native_camera: CameraHelper.useNativeCamera()
                    }, () => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
            }
        });
    }   

    /**
     * Pause or resume video
     * and show pause screen
     */
    pauseOrResume() {
        // Pause/resume video
        this.exercise_video.current.pauseOrResume();
        // Set state to show/hide pause view
        this.setState(prevState => { return { is_paused: !prevState.is_paused }});
    }

    /**
     * Pause exercise and show pause screen
     */
    pause(manually) {
        console.log('Pause exercise');
        if (this.exercise_video.current) {
            console.log('Pause video');
            this.exercise_video.current.pause();
        }
        this.setState({
            is_paused: true,
            manually_paused: (manually ? true : false) 
        });

        // Mute music
        if (this.music) {
            this.music.mute();
        }
    }

    /**
     * Resume video and hide pause screen
     */
    resume() {
        this.exercise_video.current.resume();
        this.setState({ 
            is_paused: false,
            manually_paused: false
        });

        // Resume music if not playing intro
        if (this.exercise_video.current.video.current_index > 0 && this.music) {
            this.music.unmute();
        }
    }

    /**
     * Restart the exercise from the beginning
     */
    restart() {
        this.setState({
            rep_num: 0,
            progress: 0, 
            current_set: 1,
            is_paused: false,
            manually_paused: false
        }, () => {
            if (this.music) {
                this.music.stop(false).then(() => this.exercise_video.current.restart());
            } else {
                this.exercise_video.current.restart();
            }
        });
    }

    /**
     * Exercise is done
     */
    async done() {

        console.log('DONE');

        // Stop music if playing
        // Then play finished audio
        if (this.music) {
            await this.music.stop(true).then(() =>  this.playFinishedAudio());
        }
        // Otherwise play finished audio
        else {
            this.playFinishedAudio();
        }

        // Stop interval if exists
        if (this.exercise.interval) {
            clearInterval(this.exercise.interval);
        }

        // Reset progress
        this.setState({ progress: 0 });

        // Run next set if not on last
        if (this.state.current_set < this.exercise.current_exercise.sets) {
            await this.setState({ current_set: this.state.current_set + 1 });
            // Countdown to new set
            this.countdown('set');
            if (this.exercise_video.current) {
                this.exercise_video.current.unload();
            }
        }

        // If not last exercise, go to next            
        else if (this.state.current_exercise_index < this.state.exercises.length - 1) {

            // Reset set number
            await this.setState({ current_set: 1 });

            // Go to exercise evaluation if set
            if (!this.isInstallation && this.state.user.meta.evaluation === 'after_exercise') {
                this.history.push('/evaluation/exercise/' + this.state.workout_uuid);
            } else {
                // Increase exercise index
                localStorage.setItem('current_exercise_index', this.state.current_exercise_index + 1); 
                // Reload component
                if (this.exercise_video.current) {
                    this.exercise_video.current.unload(true);
                }
                this.init();
            }
        }
        // Workout is done
        else {
            console.log('WORKOUT IS DONE');

            if (!this.isInstallation) {
                // Register workout done
                ApiHelper.Put('/workouts/' + this.state.workout_uuid + '/end');
            }

            // Remove program state from storage
            localStorage.removeItem('workout_uuid');
            localStorage.removeItem('program_uuid');

            // Set workout finished
            localStorage.setItem('workout_finished', true);

            // Remove exercise index if not evaluation per exercise is active
            if (this.state.user?.meta.evaluation !== 'after_exercise') {
                localStorage.removeItem('current_exercise_index');
            }

            // Go to exercise evaluation if set in for user
            if (!this.isInstallation && this.state.user?.meta.evaluation === 'after_exercise') {
                this.history.push('/evaluation/exercise/' + this.state.workout_uuid);
            }
            // Else go to workout evaluation if set for user
            else if (!this.isInstallation && this.state.user?.meta.evaluation === 'after_workout') {
                this.history.push('/evaluation/workout/' + this.state.workout_uuid);
            }
            // Else go to voice message if set for user
            else if (!this.isInstallation && this.state.user?.meta.voice_messages === true) {
                this.history.push('/voice');
            }
            // Else go to dashboard
            else {
                this.history.push(this.isInstallation ? '/dashboard-installation' : '/dashboard');
            }
        }
    }

    /**
     * Init music
     */
    initMusic() {
        console.log('init music');
        let user = (this.isInstallation ? this.state.installation : this.state.user);
        if (user && user.music_channel_id) {
            this.music = new MusicHelper();
            this.music.init().then(() => {
                console.log('Music inited...');
            });
        } else {
            console.log('Couldnt init music, user:', user);
        }
    }

    /**
     * Play music or unmute if already playing
     * @returns 
     */
    playMusic() {
        if (this.music?.is_playing) {
            console.log('Music is playing, unmuting...');
            this.music.unmute();
        }
        else if (this.music && (this.state.user || this.state.installation)) {
            console.log('Music is not playing, start it...');
            this.music.play({
                channel_id: (this.state.user ? this.state.user.music_channel_id : this.state.installation.music_channel_id),
                fade_in: true,
                volume: (this.state.is_paused ? 0 : null)
            });
        } else {
            console.log('Music not inited.');
        }
    }

    /**
     * Play finished audio
     */
    playFinishedAudio() {
        // Audio when exercise finished
        let audio = '';
        if (SystemHelper.isIos()) {
            audio = window.cordova.file.applicationDirectory + 'www/assets/audio/exercise-finished.mp3';
        } else {
            audio = new Audio(SystemHelper.getSystemPath('/assets/audio/exercise-finished.mp3'));
        }
        this.finished_audio = new AudioHelper(audio);
        this.finished_audio.setVolume((this.finished_audio.getVolumeSettings().audio_signals / 10) * this.FINISHED_AUDIO_VOLUME_RATIO);
        console.log('Play finished audio');
        this.finished_audio.play();
        
        // Release when done
        setTimeout(() => {
            this.finished_audio.stop();
        }, 3000);
    }

    async componentDidMount() {
        
        if (!this.isInstallation) {

            if (!UserHelper.token.get()) {
                this.history.push('/login');
                return;
            }
            
            // Get user
            await UserHelper.getUser().then((user) => {
                this.setState({ user: user });
            });
        }
        
        await this.init();

        // Init music
        this.initMusic();

    }
    
    componentWillUnmount() {
        // Clear interval
        clearInterval(this.exercise.interval);
        
        // Unload video
        if (this.exercise_video.current) {
            this.exercise_video.current.unload(true);
        }
        
        // Stop camera
        if (CameraHelper.cameraAvailable()) {
            CameraHelper.stopCamera();
        }

        // Stop pose detector
        if (this.pose_detector) {
            this.pose_detector.stop();
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div>
                <section id="view-exercise" className={(!CameraHelper.cameraAvailable() ? 'mirror' : '')}>
                    
                    <div className="top-gradient"></div>
                    
                    { CameraHelper.cameraAvailable() ?
                        <div>
                            <video id="mirror" className={!this.state.camera_ready || this.state.use_native_camera ? 'hidden' : 'rotate-' + this.config.rotate_camera_view} autoPlay playsInline></video>
                            <canvas id="mirror-blur"></canvas>  
                        </div>
                    : '' }

                    <ExerciseProgress
                        exercises={this.state.exercises} ExerciseView={this} parent={this}
                        currentIndex={this.state.current_exercise_index}
                        currentSet={this.state.current_set}
                        progress={this.state.progress} />
                    
                    {this.state.exercises[this.state.current_exercise_index] && this.state.exercises[this.state.current_exercise_index].reps > 0 ?
                    <div className="top">
                        <div className="reps">
                            <div className="caption">{ t('workout:exercise.repetition') }</div>
                            <div className="current-rep">{ this.state.rep_num }</div>
                            <div className="total-reps">{ t('workout:exercise.of') } {(
                                this.state.exercises.length > 0 ? this.state.exercises[this.state.current_exercise_index].reps : ''
                            )}</div>
                        </div>
                    </div>
                    : ''}

                    <ExerciseVideo ref={this.exercise_video} ExerciseView={this}
                        Repetitions={this.state.exercises.length > 0 && this.state.exercises[this.state.current_exercise_index]?.reps}
                        Time={this.state.exercises.length > 0 && this.state.exercises[this.state.current_exercise_index]?.time}
                        Delay={this.state.program?.meta?.delay_per_rep ? this.state.program.meta.delay_per_rep : 0}
                        Subtitles={this.state.user && this.state.user.meta.subtitles}
                    />

                    <div className="button-bar bottom">
                        <Button className={'center auto-width yellow-light fade-out' + (this.state.show_exercise_countdown || (this.exercise_video.current && !this.exercise_video.current.state.is_playing) ? ' hidden' : '')} Icon="pause" onClick={() => this.pause(true)}>{ t('workout:exercise.pause') }</Button>
                    </div>

                </section>
                <section id="view-exercise-countdown" className={!this.state.show_exercise_countdown && !this.state.show_set_countdown ? 'middle out' : 'middle' }>
                    { this.state.show_exercise_countdown && this.state.countdown_time > -1 ?
                    <div>
                        { this.state.current_exercise_index > 0 ?
                            <h2>{ t('workout:exercise.exercise_finished') }<br /><br />{ ReactHtmlParser(t('workout:exercise.next_exercise_presented_in')) }</h2>
                            :
                            <h2>{ ReactHtmlParser(t('workout:exercise.first_exercise_presented_in')) }</h2>
                        }
                        <h1 className="superbig yellow-dark">{ this.state.countdown_time }</h1>
                    </div>
                    : this.state.show_set_countdown && this.state.countdown_time > -1 ?
                    <div>
                        <h2>{ t('workout:exercise.set_finished', { number: this.state.current_set-1 }) }</h2>
                        <h2>{ t('workout:exercise.next_set_starts_in') }</h2>
                        <h1 className="superbig yellow-dark">{ this.state.countdown_time }</h1>
                    </div>
                    : this.state.loading_pose_detection ?
                        <div>
                            <h2>{ t('workout:exercise.loading') }...</h2>
                        </div>
                    : '' } 
                </section>
                <div style={{ 'display': (this.state.is_paused ? 'block' : 'none') }}>
                    <ExercisePaused ExerciseView={this} />
                </div> 
            </div>
        );
    }
}

export default withRouter(withTranslation(['workout', 'common'])(Exercise));