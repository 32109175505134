import ApiHelper from './ApiHelper';

const InstallationHelper = {

    authenticated: false,

    /**
     * Get logged in installation
     */
    getInstallation() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/installation').then(installation => {
                resolve(installation.data.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    /**
     * Get all programs for installation
     */
    getPrograms() {
        return new Promise((resolve, reject) => {
            ApiHelper.Get('/installation/programs').then(programs => {
                resolve(programs.data.data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    token: {
        set: (token) => {
            localStorage.setItem('token', token);
        },
        get: () => {
            return localStorage.getItem('token');
        }
    },

    code: {
        set: (code) => {
            localStorage.setItem('installation_id', code);
        },
        get: () => {
            return localStorage.getItem('installation_id');
        }
    },

    name: {
        set: (name) => {
            localStorage.setItem('installation_name', name);
        },
        get: () => {
            return localStorage.getItem('installation_name');
        }
    },
};

export default InstallationHelper;
