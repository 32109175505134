import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Settings from './settings/Settings';
import Button from './shared/Button';
import Reminder from './shared/Reminder';
import ScreenOff from './shared/ScreenOff';
import SystemHelper from './helpers/SystemHelper';
import UserHelper from './helpers/UserHelper';
import DataHelper from './helpers/DataHelper';
import PushHelper from './helpers/PushHelper';
import PermissionsChecker from './shared/util/PermissionsChecker';
import './Dashboard.scss';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.state = {
            user: null,
            off: false,
            settings: false,
            workout_finished: false,
            last_workout: null,
            current_exercise: {
                index: -1,
                name: null,
                image: null
            },
            active_program: null,
            no_program: null,
            no_reminder: null,
            reminder_active: false,
            next_reminder_time: null,
            next_reminder_day: null,
            screen_sleep_minutes: null
        };
        
        this.config = {};
        this.screenTimeout = null;
        this.program_interval = null
    }

    /**
     * Init Websockets for reminder
     */
    initWebsockets() {
        const component = this;
        component.reminder = new Reminder({
            uuid: component.state.user.uuid,
            locale: localStorage.getItem('locale'),
            onstart: () => {
                component.reminder.start({
                    onstart: () => { component.showReminder(component) }
                });
            }
        });
    }

    /**
     * Loads user into state
     */
    async loadUser() {
        const component = this;
        return new Promise(async (resolve, reject) => {
            UserHelper.getUser().then(async (usr) =>{
                component.setState({ user: usr }, () => {
                    resolve();
                });
            }).catch(error => {
                console.error(error);
                UserHelper.logout(component);
                reject();
            });
        });
    }

    /**
     * Show reminder screen
     */
    showReminder(component) {

        console.log('show reminder');

        if (!component) {
            component = this;
        }

        // Remove current exercise
        // to start program from beginning
        localStorage.setItem('current_exercise_index', -1);
        component.setState({ current_exercise: {
            index: -1,
            name: null,
            image: null
        }});

        // Set new message
        component.setState({ reminder_active: true });

        // Wake up screen if off
        if (component.state.off) {
            component.wakeUp();
        }
    }

    /**
     * Fetch current program
     * @param {Current component} component 
     */
    initProgram = async () => {
        return new Promise(async (resolve, reject) => {
            // Check current exercise
            await this.setState({ current_exercise: {index: (localStorage.getItem('current_exercise_index') ? parseInt(localStorage.getItem('current_exercise_index')) : -1) }});
            let current_index = this.state.current_exercise.index;

            // Get active program
            // Check if any exercises has finished and get aborted exercise
            await UserHelper.getUserProgram().then(async (program) => {

                // There is no active program
                if (program === null) {
                    await this.setState({ no_program: true });
                }
                // Or there is
                else {
                    // If there is a new program
                    if (localStorage.getItem('program_uuid') !== program.uuid) {
                        // Save new program UUID
                        localStorage.setItem('program_uuid', program.uuid);
                        // Reset index
                        localStorage.setItem('current_exercise_index', -1);
                        current_index = -1;
                    }
                    // Reset current exercise if program has changed
                    // with fewer exercises
                    else if (current_index >= program.exercises.length) {
                        localStorage.setItem('current_exercise_index', -1);
                        current_index = -1;
                    }

                    // Get locale name of exercise current exercise if exists

                    let program_name = null;
                    if (current_index > -1) {
                        if (program.exercises[current_index].names && program.exercises[current_index].names[i18next.language]) {
                            program_name = program.exercises[current_index].names[i18next.language];
                        } else {
                            program_name = program.exercises[current_index].name;
                        }
                    }

                    // Update state
                    await this.setState(
                        { current_exercise: {
                            index: current_index,
                            name: (current_index > -1 ? program_name : null),
                            image: (current_index > -1 ? program.exercises[current_index].image : null)
                        },
                        active_program: program,
                        no_program: false }
                    );
                    }
            }).catch(async (error) => {
                console.error(error);
                await this.setState({ no_program: true });
            });

            // Check if workout is finished today
            this.checkWorkoutFinished();

            // Get next reminder from backend
            if (this.state.user.meta.reminders) {
                UserHelper.getNextReminder().then(async (reminder) => {
                    console.log('reminder', reminder);
                    if (reminder) {
                        // Calculate and translate day for next reminder
                        const dt = SystemHelper.stringToDate(reminder);
                        console.log('dt', dt);
                        const now = new Date();
                        let next_reminder_day = null;
                        let next_reminder_hour = dt.getHours();

                        console.log(dt.getDay() + '/' + now.getDay());

                        if (dt.getDay() === now.getDay() && next_reminder_hour > now.getHours()) {
                            next_reminder_day = i18next.t('common:days.today');
                        } else if (dt.getDay() !== now.getDay()) {
                            if ((dt.getDay() === now.getDay() + 1) || (dt.getDay() === 0 && now.getDay() === 6)) {
                                next_reminder_day = i18next.t('common:days.tomorrow');
                            } else {
                                const days_en = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                                next_reminder_day = i18next.t('common:on') + ' ' + i18next.t(`common:days.${days_en[dt.getDay()]}`);
                            }
                        }

                        // Set date and time in state
                        await this.setState({
                            next_reminder_day: next_reminder_day,
                            next_reminder_time: (next_reminder_hour < 10 ? '0' + next_reminder_hour : next_reminder_hour) + ':00'
                        });
                        resolve();
                    } else {
                        await this.setState({ no_reminder: true });
                        resolve();
                    }
                });
            } else {
                await this.setState({ no_reminder: true });
                resolve();
            }
        });
    }

    async componentDidMount() {

        const component = this;

        // You shouldn't be here if this is an installation
        if (localStorage.getItem('admin_token') === 'installation') {
            this.history.push('/dashboard-installation');
            return;
        }

        // Load user
        await this.loadUser();

        // Init websockets
        this.initWebsockets();

        // Init push on iOS/Android
        // if activated for user
        if (this.state.user.meta.push && window.plugins?.OneSignal) {
            console.log('Activate push...');
            PushHelper.init('102dd4f1-7a81-42a0-8efa-b91964ff42bf', (data) => {
                console.log('opened push', data);
                this.setState({ reminder_active: true });
                this.screenOn();
            });
        } else {
            console.log('window.plugins', window.plugins);
            console.log('Push setting', this.state.user.meta.push);
            console.log('OneSignal plugin', window.plugins?.OneSignal ? 'exists' : 'does not exist');
        }

        // Check for permissions
        let pc = new PermissionsChecker(window);
        pc.check();

        // Init active program
        await this.initProgram();

        // Refresh program every 60 s
        this.program_interval = setInterval(() => this.initProgram(this), 60000);

        // Get config
        await DataHelper.get('config').then(async (config) => {
            component.config = config;
            // Save screen sleep minutes in state
            //console.log('setting config...')
            await component.setState({ screen_sleep_minutes: config.screen_sleep_minutes });
            // Init screen timeout
            component.initScreenTimeout();
        });

        // Refresh on app resume
        document.addEventListener('resume', () => {
            this.wakeUp();
        }, false);
    }

    /**
     * Wake up screen
     */
    wakeUp() {
        // Fetch program
        this.initProgram();

        // Recheck workout status
        this.checkWorkoutFinished();

        // Turn on screen
        this.screenOn();

        // Save and hide settings if showing
        if (this.state.settings) {
            this.setState({ settings: false });
        }
    }

    componentWillUnmount() {
        // Turn screen on
        this.screenOn();

        if (this.reminder) {
            // Stop reminder timeout
            this.reminder.inactivate();

            // Unsubscribe to websocket channel
            this.reminder.unsubscribe();
        }

        // Stop program refresh
        if (this.program_interval) {
            clearInterval(this.program_interval);
        }

        // Remove program refresh from resume
        document.removeEventListener('resume', this.initProgram, false);
    }

    /**
     * Check if workout with active program has finished
     */
    checkWorkoutFinished() {
        const component = this;
        let workout_uuid = localStorage.getItem('workout_uuid');
        let program_uuid = localStorage.getItem('program_uuid');

        // Set last workout in state
        this.setState({ last_workout: localStorage.getItem('last_workout') });

        // If no running program
        if (!(workout_uuid && program_uuid)) {
            this.setState({ workout_finished: true });
        }
        // Else check active program
        else {
            UserHelper.getUserProgram().then(async function(program) {
                if (program) {
                    if (program_uuid === program.uuid && workout_uuid) {
                        component.setState({workout_finished: false});
                    } else {
                        component.setState({workout_finished: true});
                    }
                }
            });
        }
    }

    /**
     * Restart the workout
     */
    restartWorkout() {
        localStorage.removeItem('workout_finished');
        localStorage.setItem('current_exercise_index', 0);
        this.history.push('/workout-intro');
    }

    /**
     * Init timeout before screen is black 
     */
    initScreenTimeout() {
        // Clear if timeout already exists
        if (this.screenTimeout) {
            clearInterval(this.screenTimeout);
        }

        // Abort if no sleep timeout set
        if (!this.state.screen_sleep_minutes) {
            return;
        }

        const component = this;

        // Shut down screen in five min
        this.screenTimeout = setTimeout(function() {
            component.screenOff();
        }, 60000 * component.state.screen_sleep_minutes);
    }

    /**
     * Turn off screen
     */
    screenOff() {
        this.setState({ off: true });
    }

    /**
     * Turn on screen
     */
    screenOn() {
        // Set state to hide off screen
        this.setState({ off: false });

        // Reset screen timeout
        this.initScreenTimeout();
    }

    /**
     * Handle click on view
     */
    clickView() {
        // Inactivate alarm if on
        if (this.reminder) {
            this.reminder.inactivate();
        }

        // Wake up screen if off
        if (this.state.off) {
            this.wakeUp();
        } else {
          // Always reset screen timeout on click
          this.initScreenTimeout();
        }
    }

    /**
     * Open settings
     */
    openSettings(card) {
        this.setState({ settingsCard: card, settings: true });
    }

    render() {

        const { t } = this.props;

        return (
            <div>
                <SkeletonTheme color="#eecbc5" highlightColor="#eed9d6">
                    <section id="view-dashboard" className="blobs-light" onClick={() => this.clickView()}>

                        { this.state.active_program || this.state.no_program !== null ? 
                        <div className="wrapper middle">
                        { this.state.no_program ? (
                           <div>
                                <h1>{ t('dashboard:no_program_today') }</h1>
                                <h3>{ t('dashboard:program_will_show_here') }</h3>
                                <h3>{ t('dashboard:contact_your_caregiver') }</h3>
                                <p>{ t('dashboard:will_sleep', { minutes: this.state.screen_sleep_minutes }) }</p>
                            </div>
                        ) : (this.state.reminder_active ? (
                            <div>
                                <h1>{ t('dashboard:time_for_workout1') }</h1>
                                <h3>{ t('dashboard:get_ready') }</h3>
                            </div>
                        ) : !this.state.last_workout && this.state.current_exercise.index === -1 ? (
                            <div>
                                <h1>{this.state.user ? t('dashboard:welcome', { first_name: this.state.user.first_name }) : <Skeleton count="2" />}</h1>
                                <h3>{this.state.user ? t('dashboard:first_workout') : <Skeleton count="2" />}</h3>
                            </div>
                        ) : this.state.current_exercise.index > -1 ? (
                            <div>
                                <h1>{this.state.user ? t('dashboard:welcome_back', { first_name: this.state.user.first_name }) : <Skeleton count="2" />}</h1>
                                <h3>{this.state.current_exercise ? ReactHtmlParser(t('dashboard:aborted_exercise', { number: (this.state.current_exercise.index + 1) })) : <Skeleton count="2" />}</h3>
                                <div className="aborted-exercise">
                                    <img src={this.state.current_exercise.image} className="thumb" alt="" />
                                    <h4>{this.state.current_exercise.name}</h4>
                                </div>
                            </div>
                        ) : this.state.last_workout && !this.state.workout_finished ? (
                            <div>
                                <h1>{this.state.user ? t('dashboard:welcome_back', { first_name: this.state.user.first_name }) : <Skeleton/>}</h1>
                                <h3>{this.state.user ? t('dashboard:time_for_workout2') : <Skeleton count="1" />}</h3>
                            </div>
                        ) : (
                            <div>
                                <h1>{this.state.user ? t('dashboard:well_done', { first_name: this.state.user.first_name }) : <Skeleton />}</h1>
                                <h3>{
                                    this.state.user && this.state.next_reminder_day && this.state.next_reminder_time
                                        ? ReactHtmlParser(t('dashboard:i_remind_you', { reminder_day: this.state.next_reminder_day, reminder_time: this.state.next_reminder_time }))
                                        : this.state.no_reminder ? t('dashboard:welcome_back_for_next_workout') : <Skeleton count="2" />
                                }</h3>
                                <p>{ t('dashboard:will_sleep', { minutes: this.state.screen_sleep_minutes }) }</p>
                            </div>
                        ))}

                        <div className="button-bar bottom-1-up">
                            <Button className={'blue-light' + (this.state.no_program ? ' center' : '')} Icon="settings" onClick={() => this.openSettings() }>{ t('dashboard:buttons.settings') }</Button>
                            {( !this.state.no_program && this.state.current_exercise.index === -1 ? (
                                <Button className="blue-dark" Icon="arrow-right" onClick={() => this.history.push('/workout-intro')}>{ t('dashboard:buttons.begin') }</Button>
                            ) : ( !this.state.no_program && this.state.current_exercise.index > -1 ? (
                                <Button className="blue-dark" Icon="arrow-right" onClick={() => this.history.push('/exercise')}>{ t('dashboard:buttons.restart_exercise') }</Button>
                            ) : ( !this.state.no_program ? (
                                <Button className="blue-light" Icon="reload" onClick={() => this.restartWorkout()}>{ t('dashboard:buttons.restart_workout') }</Button>
                            ) : '')))}
                        </div>
                        <div className="button-bar bottom">
                            <Button className="transparent center" onClick={() => this.screenOff() }>{ t('dashboard:buttons.shutdown') }</Button>
                        </div>
                    
                    </div>
                    : (
                        <div className="wrapper middle">
                            <h1><Skeleton /></h1>
                            <h3><Skeleton /></h3>
                        </div>
                    )}
                </section>

                { // Screen is off
                ( this.state.off ? (
                    <ScreenOff Parent={this} />
                ) : '' )}

                { // Show settings
                ( this.state.settings ? (
                    <Settings Parent={this} card={this.state.settingsCard} />
                ) : '' )}

             </SkeletonTheme>
            </div>
        );
    }
}

export default withRouter(withTranslation(['dashboard', 'settings', 'common'])(Dashboard));
