import SystemHelper from "./SystemHelper";

class AudioHelper  {

  /**
   * Contructs the class
   * @param {URI or Audio object} audio 
   */
  constructor(audio) {

    this.defaults =  {
      SPOKEN_INSTRUCTIONS: 10,
      MUSIC: 7,
      AUDIO_SIGNALS: 7
    };

    // Don't setup audio of no source or object is provided
    if (!audio) {
      return;
    }

    this.audio = audio;
    this.is_muted = false;
    this.media_status = 0;

    // Use Media plugin in iOS
    if (SystemHelper.isIos() && typeof audio === 'string') {
      console.log('Using Cordova Media...', audio);

      // eslint-disable-next-line
      this.media = new Media(audio, this.mediaSuccess, this.mediaError, this.mediaStatus);
    }
    // Else use Web Audio API and AudioContext
    else {
      console.log('Using Web Audio API...', audio);
      if (typeof audio === 'string') {
        this.audio_element = new Audio(audio);
      } else {
        this.audio_element = audio;
      }
      this.context = new (window.AudioContext || window.webkitAudioContext)();
      this.source = this.context.createMediaElementSource(this.audio_element);
      this.gain = this.context.createGain();

      this.source.connect(this.gain);
      this.gain.connect(this.context.destination);

      this.paused_time = 0;
    }
  }

  mediaSuccess() {
    console.log('Media success', this.audio);
  }

  mediaError(error) {
    console.log('Media error - ' + this.audio, JSON.stringify(error));
  }

  mediaStatus(status) {
    this.media_status = status;
    console.log('Media status - ' + this.audio, status);
  }

  /**
   * Get audio
   * @returns Audio object or source
   */
  getAudio() {
    return this.audio;
  }

  /**
   * Gets current audio volumes
   */
  getVolumeSettings()  {
    let audio_volume = {};
    try {
      audio_volume = JSON.parse(localStorage.getItem('audio_volume'));
      if (
        isNaN(audio_volume.spoken_instructions) ||
        isNaN(audio_volume.music) ||
        isNaN(audio_volume.audio_signals)
      ) {
          return this.resetSettings();
        }
    } catch (error) {
      console.error('Could not get audio volume from local storage, resetting...');
      return this.resetSettings();
    }
    return audio_volume;
  }

  /**
   * Plays the audio
   */
  play() {
    console.log('Playing audio', this.audio);
    if (this.media) {
      this.media.play();
      this.media.setRate(1);
      console.log('Media playing');
    }
    else if (this.context && this.audio_element) {
      console.log('play audio context', this.context.state);
      if (this.context.state === 'suspended') {
        this.context.resume();
      }
      this.audio_element.load();
      this.audio_element.play();
    }
    else {
      console.log('No audio to play!');
    }
  }

  /**
   * Stops and releases the audio
   */
  stop() {
    return new Promise(resolve => {
      console.log('Stopping audio');
      if (this.media) {
        this.media.stop();
      } else {
        this.pause();
      }

      if (this.media) {
        let tries = 0;
        // Check for media status stopped
        this.stop_interval = setInterval(() => {
          if (this.media_status === 4 || tries === 5) {
            clearInterval(this.stop_interval);
            if (tries < 5) {
              console.log('Media STOPPED! Releasing and resolving.', this.media_status);
            } else {
              console.log('Tried 5 times, didnt stop');
              this.media.stop();
            }
            this.release();
            resolve();
          } else {
            tries++;
            console.log('Media not stopped yet...', this.media_status);
          }
        }, 200);
      } else {
        this.release();
        resolve();
      }
    });
  }

  /**
   * Pauses audio
   */
  pause() {
    console.log('Pausing audio');
    if (this.media) {
      this.media.pause();
      console.log('Media paused');
    } else if (this.audio_element && this.context?.state !== 'suspended') {
      this.audio_element.pause();
      this.paused_time = this.audio_element.currentTime;
      console.log('Paused audio at', this.paused_time);
      //this.context.suspend();
      console.log('Audio paused');
    }
    else {
      console.log('No audio to pause!');
    }
  }

  /**
   * Resumes audio
   */
  resume() {
    console.log('Resuming audio');
    this.is_muted = false;
    if (this.media) {
      this.media.play();
    } else if (this.audio_element && this.context) {
      // Reload audio object because it might have been unloaded
      //this.audio_element.load();

      // Play audio from paused time
      this.audio_element.currentTime = this.paused_time;
      this.audio_element.play();

      console.log('Audio resumed');
    }
    else {
      console.log('No audio to resume!');
    }
  }


  /**
   * Clean up audio memory
   */
  release() {
    console.log('release media/audio')
    if (this.media) {
      this.media.release();
    }
    else if (this.audio_element) {
      this.audio_element.pause();
      this.source.disconnect();
      //this.audio_element = null;
      console.log('Web Audio released');
    }
    if (this.context?.state && this.context?.state !== 'closed') {
      console.log('state is', this.context.state);
      this.context.close();
    }
  }

  /**
   * Sets the volume of the audio
   * @param {The volume (0-1)} volume 
   */
  setVolume(volume) {
    console.log('setting volume', volume);

    this.is_muted = (volume === 0);
    console.log('muted', this.is_muted);

    if (this.media) {
      this.media.setVolume(volume);
    }
    else if (this.gain?.gain && this.context) {
      this.gain.gain.setValueAtTime(volume, this.context.currentTime);
    }
  }

  /**
   * Get current time of audio
   */
  async getTime() {
    return new Promise((resolve, reject) => {
      if (this.media) {
        this.media.getCurrentPosition(
          time => resolve(time),
          error => reject(error)
        );
      }
      else if (this.audio_element) {
        resolve(this.audio_element.currentTime);
      }
      else {
        reject('No audio source');
      }
    });
  }

  /**
   * Set current time of audio
   * @param {Goes to given time in audio file} time 
   */
  setTime(time) {
    if (this.media) {
      this.media.seekTo(time);
    }
    else if (this.audio_element) {
      this.audio_element.currentTime = time;
    }
  }

  /**
   * Fade audio
   * @param {The original volume} from 
   * @param {The target volume} to 
   * @param {The fade duration} seconds 
   */
  fade(from, to, seconds) {
    console.log((to > from ? 'Fade in' : 'Fade out'), this.audio);

    return new Promise(resolve => {

      // Use interval to fade
      let current_volume = from;
      const step = 0.005;
      const interval_time = (seconds * 1000) / (Math.abs(to - from) / step);

      console.log('Interval time is', interval_time);

      const interval = setInterval(() => {
        if (to > from) {
          current_volume += step;
        } else {
          current_volume -= step;
        }

        if ((current_volume >= to && to >= from) || (current_volume <= to && to <= from)) {
          // Fade is done
          clearInterval(interval);

          console.log('volume (done)', to);

          // Volume is 0 if muted
          let volume = (this.is_muted ? 0 : to);

          if (this.media) {
            this.media.setVolume(volume);
          } else if (this.gain?.gain && this.context) {
            this.gain.gain.setValueAtTime(volume, this.context.currentTime);
          }

          resolve();

        } else {

          // Volume is 0 if muted
          let volume = (this.is_muted ? 0 : current_volume);

          console.log('volume', current_volume);

          if (this.media) {
            this.media.setVolume(volume);
          } else if (this.gain?.gain && this.context) {
            this.gain.gain.setValueAtTime(volume, this.context.currentTime);
          }

          // Abort fade if exercise is muted
          if (this.is_muted) {
            console.log('Muted - aborting fade');
            clearInterval(interval);
            resolve();
          }
        }
        
      }, interval_time);
    });
  }

  /**
   * Reset to default and save audio settings
   * @returns Default audio settings
   */
  resetSettings() {
    let audio_volume = {};
    audio_volume.spoken_instructions = this.defaults.SPOKEN_INSTRUCTIONS;
    audio_volume.music = this.defaults.MUSIC;
    audio_volume.audio_signals = this.defaults.AUDIO_SIGNALS;

    localStorage.setItem('audio_volume', JSON.stringify(audio_volume));
    return audio_volume;
  }
};

export default AudioHelper;