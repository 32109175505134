import React, { Component } from 'react';
import ApiHelper from '../helpers/ApiHelper';
import InstallationHelper from '../helpers/InstallationHelper';
import MusicHelper from '../helpers/MusicHelper';
import UserHelper from '../helpers/UserHelper';
import Button from '../shared/Button';
import './Settings.scss';

class SettingsMusic extends Component {

    constructor(props) {
        super(props);
        this.history = this.props.history;
        this.is_installation = this.props.parent.is_installation;
        this.state = {
            channels: [],
            selected_channel_id: null,
            isScrolling: false,
            initialY: null
        };
        this.music = null;
    }

    componentDidMount() {
        // Get installation or user and set selected channel
        if (this.is_installation) {
            InstallationHelper.getInstallation().then(installation => {
                this.setState({ selected_channel_id: installation.music_channel_id });
            });
        } else {
            UserHelper.getUser().then(user => {
                this.setState({ selected_channel_id: user.music_channel_id });
            });
        }

        // Init music
        this.music = new MusicHelper();
        this.music.init().then(() => {
            this.setState({ channels: this.music.channels });
        });
    }

    componentWillUnmount() {
        this.music.stop(true);
    }

    /**
     * Select channel and saved ID in API
     * @param {ID of the channel} id 
     * @returns 
     */
    selectChannel(id) {

        // Abort if already selected
        if (id === this.state.selected_channel_id) {
            return;
        }

        // Update in state
        this.setState({ selected_channel_id: id });
        
        // Update in API
        let settings_endpoint = (this.is_installation ? '/installation/settings' : '/user/settings');
        ApiHelper.Put(settings_endpoint, {
            music_channel_id: id
        });

        // Play a sample
        // or stop if no music selected
        if (id !== null) {
            this.music.play({
                channel_id: id
            });
        } else {
            this.music.stop();
        }
    }

    /**
     * Function to handle when pointer or finger is pressed down
     * */
    handlePointerDown = event => {
        this.setState({
        initialY: event.clientY || event.touches[0].clientY
        });
    }

    /**
     * Function to handle when pointer or finger is released
     * */
    handlePointerUp = () => {
        this.setState({
        isScrolling: false,
        initialY: null
        });
    }

    /**
     * Function to handle when pointer or finger moves
     * */
    handlePointerMove = event => {
        const { initialY, isScrolling } = this.state;
        if (!isScrolling && initialY !== null) {
        const currentY = event.clientY || event.touches[0].clientY;
        const deltaY = Math.abs(currentY - initialY);
        if (deltaY > 10) { // Adjust threshold as needed
            this.setState({
            isScrolling: true
            });
        }
        }
    }


    render() {

        const { t } = this.props.parent.state.parentComponent.props;
        let selected = this.state.selected_channel_id === null;

        return (
            <div>
                <h3>{ t('settings:panels.music.select_music') }</h3>
                <div className="select-music"
                    onMouseDown={this.handlePointerDown}
                    onTouchStart={this.handlePointerDown}
                    onMouseMove={this.handlePointerMove}
                    onTouchMove={this.handlePointerMove}
                    onMouseUp={this.handlePointerUp}
                    onTouchEnd={this.handlePointerUp}
                >
                    <Button className={'center full-width' + (selected ? ' blue-dark' : ' white')} IconLeft={(selected ? 'music-white' : 'music-blue')} onClick={() => this.selectChannel(null)} preventScrollTap={true}>
                        <span className="d-block w-100 padding-1 text-left blue-dark">{ t('settings:panels.music.no_music')}</span>
                    </Button>
                    { this.state.channels.map(channel => {
                        selected = this.state.selected_channel_id === channel.id;
                        return (
                            <Button className={'center full-width' + (selected ? ' blue-dark' : ' white')} IconLeft={(selected ? 'music-white' : 'music-blue')} key={'channel-' + channel.id}
                                onClick={() => { if (!this.state.isScrolling) { this.selectChannel(channel.id); } }} preventScrollTap={true}>
                                <span className="d-block w-100 padding-1 text-left blue-dark">{ t(`settings:panels.music.channels.${channel.title}`) }</span>
                            </Button>
                        );
                    })}
                </div>
                <div className="select-music-fader"></div>
            </div>
        );
    }
}

export default SettingsMusic;