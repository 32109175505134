const CameraHelper = {

    camera_track: null,

    /**
     * Returns true if camera is available
     */
    cameraAvailable() {
        // Don't use camera on big Intel screen
        if (window.device && window.device.model === 'cht_cr_rvp') {
            return false;
        }
        // Check for plugin or use browser
        return (window.CameraPreview || navigator.mediaDevices?.getUserMedia ? true : false);
    },

    /**
     * Returns true or false depending on platform
     */
    useNativeCamera() {
        if (window.CameraPreview && window.device.model !== 'cht_cr_rvp') {
            return true;
        } else {
            return false;
        }
    },

    /**
     * Start device camera
     */
    async startCamera(use_pose_detection) {

        return new Promise((resolve, reject) => {
            // Use CameraPreview for iOS/Android
            // but not on the big Intel screen
            if (this.useNativeCamera()) {
                // Camera options
                let options = {};
                /*
                if (use_pose_detection) {
                    options = {
                        cameraFacing: 'front',
                        use: 'data',
                        width: 1008,
                        height: 756
                    };
                }
                else {
                    */
                    options = {
                        x: 0,
                        y: 0,
                        width: window.screen.width,
                        height: window.screen.height,
                        // eslint-disable-next-line
                        camera: CameraPreview.CAMERA_DIRECTION.FRONT,
                        toBack: true,
                        tapPhoto: false,
                        tapFocus: false,
                        previewDrag: false,
                        storeToFile: false,
                        disableExifHeaderStripping: false
                    };
                //}

                // Set background transparent so preview is visible
                document.getElementById('view-exercise').style.background = 'transparent';

                console.log('starting camera...');

                // Start camera
                // eslint-disable-next-line
                CameraPreview.startCamera(options);

                resolve();

            } else {
                // Get webcam for browser
                const constraints = {
                    video: {
                        width: {min: 1280 },
                        height: {min: 720 },
                        facingMode: 'user'
                    },
                    audio: false
                }

                console.log('Running getUserMedia...');

                // Get video element for streaming
                let mirror = document.querySelector('#mirror');

                // Get device camera
                navigator.mediaDevices.getUserMedia(constraints).then(async (stream) => {
                        // eslint-disable-next-line
                        this.camera_track = stream.getTracks()[0];
                        mirror.srcObject = stream;

                        console.log('streaming...');

                        // Show camera stream
                        setTimeout(() => {
                            // We are finished

                            const capabilities = this.camera_track.getCapabilities();
                            console.log('camera capabilities', JSON.stringify(capabilities));

                            resolve();
                        }, 1000);
                    })
                    .catch((err) => {
                        console.error(err);
                        resolve();
                });
            }
        });
    },

    /**
     * Stop the camera
     */
    stopCamera() {
        // Using CameraPreview
        if (this.useNativeCamera()) {
            // eslint-disable-next-line
            CameraPreview.stopCamera();
        }
        // Using user media
        else {
            if (this.camera_track) {
                this.camera_track.stop();
            }
        }
    }
};

export default CameraHelper;