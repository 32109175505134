import { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import ApiHelper from "../helpers/ApiHelper";
import UserHelper from "../helpers/UserHelper";
import Button from "../shared/Button";
import ReminderSetting from "./ReminderSetting";
import "./Settings.scss";

class SettingsReminders extends Component {
  constructor(props) {
    super(props);
    this.history = this.props.history;
    this.is_installation = this.props.parent.is_installation;
    this.state = {
      programs: [],
      reminderNavMode: "",
      openReminder: 0,
      user: null,
      isScrolling: false,
      initialY: null
    };
  }

  /**
   * Function to handle when pointer or finger is pressed down
   * */
  handlePointerDown = event => {
    this.setState({
      initialY: event.clientY || event.touches[0].clientY
    });
  }

  /**
   * Function to handle when pointer or finger is released
   * */
  handlePointerUp = () => {
    this.setState({
      isScrolling: false,
      initialY: null
    });
  }

  /**
   * Function to handle when pointer or finger moves
   * */
  handlePointerMove = event => {
    const { initialY, isScrolling } = this.state;
    if (!isScrolling && initialY !== null) {
      const currentY = event.clientY || event.touches[0].clientY;
      const deltaY = Math.abs(currentY - initialY);
      if (deltaY > 10) { // Adjust threshold as needed
        this.setState({
          isScrolling: true
        });
      }
    }
  }

  componentDidMount() {
    let component = this;
    // Get user's programs
    if (!this.is_installation) {
      ApiHelper.Get("/programs").then((programs) => {
        component.setState({ programs: programs.data });
      });

      // Get user
      UserHelper.getUser().then((user) => {
        this.setState({ user: user });
      });
    }
  }

  render() {
    const { t } = this.props.parent.state.parentComponent.props;

    return (
      <div>
        <h3>{t("settings:reminders")}</h3>
        {this.state.user && this.state.user.meta?.reminders === true ? (
          this.state.programs.length > 1 ? (
            <div className="reminders-wrapper">
              {this.state.reminderNavMode !== "open" ? (
                <p>{t("settings:panels.reminders.select_program")}</p>
              ) : (
                <p>{t("settings:panels.reminders.select_time")}</p>
              )}
              <div className={"scroll-reminders " + this.state.reminderNavMode}>
                <div className="col-100"
                  onMouseDown={this.handlePointerDown}
                  onTouchStart={this.handlePointerDown}
                  onMouseMove={this.handlePointerMove}
                  onTouchMove={this.handlePointerMove}
                  onMouseUp={this.handlePointerUp}
                  onTouchEnd={this.handlePointerUp}
                >
                  {this.state.programs.map((program, i) => {
                    return (
                      <Button
                        className="center white full-width wrap"
                        Icon="arrow-right-blue"
                        IconRight="arrow-right-blue"
                        key={"reminder-button-" + i}
                        onClick={() => {
                          console.log('click - scrolling', this.state.isScrolling);
                          if (!this.state.isScrolling) {
                            this.setState({
                              openReminder: i,
                              reminderNavMode: "open",
                            });
                          }
                        }}
                      >
                        <span>{program.name}</span>
                      </Button>
                    );
                  })}
                </div>
                <div className="col-100">
                  {this.state.programs.map((program, i) => {
                    return (
                      <div
                        className={
                          this.state.openReminder !== i ? "d-none" : ""
                        }
                        key={ 'reminder-setting-' + i }
                      >
                        <ReminderSetting
                          program={program}
                          parent={this}
                          key={"reminder-" + i}
                        />
                      </div>
                    );
                  })}
                  <Button
                    className="center"
                    onClick={() => this.setState({ reminderNavMode: "" })}
                  >
                    {t("common:back")}
                  </Button>
                </div>
              </div>
            </div>
          ) : this.state.programs.length === 1 ? (
            <div>
              <p>{t("settings:panels.reminders.select_time")}</p>
              <div className="workout-programs">
                <ReminderSetting
                  program={this.state.programs[0]}
                  key={"reminder-0"}
                  parent={this}
                />
              </div>
            </div>
          ) : (
            ReactHtmlParser(
              '<p>' + t('settings:panels.reminders.no_active_program') + '</p>'
            )
          )
        ) : (
          ReactHtmlParser(
            '<p>' + t('settings:panels.reminders.reminders_not_activated') + '</p>'
          )
        )}
      </div>
    );
  }
}

export default SettingsReminders;
