import React, { Component } from 'react';

class VideoTransform extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.lastDistance = 0;
    this.lastTouchCenter = { x: 0, y: 0 };
    this.isDragging = false;
    this.initialPos = { x: 0, y: 0 };
    this.state = {
      scale: 1,
      width: 0,
      height: 0,
      pos: { x: 0, y: 0 },
      lastPos: { x: 0, y: 0 }
    };
    this.MIN_SCALE = 0.5;
    this.MAX_SCALE = 2.5;
  }

  componentDidMount() {
    const video = this.videoRef.current;

    // Event listeners for mouse and touch events
    video.addEventListener('mousedown', this.handleMouseDown);
    video.addEventListener('mousemove', this.handleMouseMove);
    video.addEventListener('mouseup', this.handleMouseUp);
    video.addEventListener('wheel', this.handleWheel);
    video.addEventListener('touchstart', this.handleTouchStart);
    video.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    video.addEventListener('touchend', this.handleTouchEnd);

    // Reset on resize
    window.addEventListener('resize', () => {
      this.resetStyles();
      this.setStyles();
    });
  }

  componentWillUnmount() {
    const video = this.videoRef.current;

    // Remove event listeners when component is unmounted
    video.removeEventListener('mousedown', this.handleMouseDown);
    video.removeEventListener('mousemove', this.handleMouseMove);
    video.removeEventListener('mouseup', this.handleMouseUp);
    video.removeEventListener('wheel', this.handleWheel);
    video.removeEventListener('touchstart', this.handleTouchStart);
    video.removeEventListener('touchmove', this.handleTouchMove);
    video.removeEventListener('touchend', this.handleTouchEnd);
  }

  setStyles() {
    const video = this.videoRef.current;
    
    if (!video) {
      console.log('No video, aborting...');
      return;
    }

    let initialX = 0;
    let initialY = 0;
    let initialSize = 0;
    let initialScale = 1;
    let stylesSet = false;
    
    if (localStorage.getItem('video_setting')) {
      try {
        // Get saved setting
        const setting = JSON.parse(localStorage.getItem('video_setting'));

        initialX = setting.pos.x;
        initialY = setting.pos.y;
        initialSize = setting.width;
        initialScale = setting.scale;
        stylesSet = true;
      }
      catch (e) {
        console.log('Saved video settings corrupt, resetting', e);
        // Reset if corrupt settings saved
        this.resetStyles();
      }
    } else {
      console.log('No video setting...');
    }
    
    // If no saved settings found, set default
    if (!stylesSet) {
      const parentElement = video.parentElement;
      const parentWidth = parentElement.clientWidth;
      const parentHeight = parentElement.clientHeight;
      const margin = parentWidth * 0.1;

      // Calculate initial size and position
      if (parentWidth < parentHeight) {
        initialX = parentWidth - parentWidth / 1.5 - margin / 4;
        initialY = (parentHeight / 2) - parentHeight / 5;
        initialSize = parentWidth / 1.5;
      } else {
        initialX = parentWidth - parentWidth / 2 + margin;
        initialY = (parentHeight / 2) - parentHeight / 4;
        initialSize = parentHeight / 2;
      }
    }

    this.setState({
      pos: {
        x: initialX,
        y: initialY
      },
      width: initialSize,
      height: initialSize,
      scale: initialScale
    });
  }

  /**
   * Reset saved video settings
   */
  resetStyles = () => {
    localStorage.removeItem('video_setting');
  }

  /**
   * Save video size and position in local storage
   */
  saveSetting = () => {
    console.log('saving setting...');
    let setting = {
      pos: this.state.pos,
      width: this.state.width,
      height: this.state.height,
      scale: this.state.scale
    };

    localStorage.setItem('video_setting', JSON.stringify(setting));
  };

  calculateBounds = () => {
    const video = this.videoRef.current;
    const { clientWidth, clientHeight } = video;
    const parentElement = video.parentElement;
    const parentWidth = parentElement.clientWidth;
    const parentHeight = parentElement.clientHeight;

    // Calculate the bounds based on scale, position, and parent size
    const minX = -(clientWidth / 2);
    const maxX = parentWidth - (clientWidth / 2);
    const minY = -(clientHeight / 2);
    const maxY = parentHeight - (clientHeight / 2);

    return { minX, maxX, minY, maxY };
  };

  handleMouseDown = (event) => {
    if (this.props.enable) {
      // Start dragging the video element on mouse down
      this.isDragging = 'mouse';
      this.setState({ lastPos: { x: event.clientX, y: event.clientY } });
    }
  };

  handleMouseMove = (event) => {
    if (this.props.enable && this.isDragging === 'mouse') {
      // Move the video element based on mouse movement
      const dx = event.clientX - this.state.lastPos.x;
      const dy = event.clientY - this.state.lastPos.y;

      this.setState((prevState) => {
        const { pos } = prevState;
        const { minX, maxX, minY, maxY } = this.calculateBounds();

        //console.log('bounds', this.calculateBounds());

        // Calculate the new position within the bounds
        const nextX = Math.min(Math.max(pos.x + dx, minX), maxX);
        const nextY = Math.min(Math.max(pos.y + dy, minY), maxY);

        return {
          pos: {
            x: nextX,
            y: nextY
          },
          lastPos: { x: event.clientX, y: event.clientY }
        };
      });
    }
  };

  handleMouseUp = () => {
    // Stop dragging the video element on mouse up
    this.isDragging = false;
    // Save setting
    this.saveSetting();
  };

  handleWheel = (event) => {
    event.preventDefault();
    if (this.props.enable) {
      // Handle zooming using mouse wheel
      this.setState((prevState) => {
        const { scale } = prevState;
        const nextScale = Math.max(this.MIN_SCALE, Math.min(this.MAX_SCALE, scale - event.deltaY * 0.01));
        const { minX, maxX, minY, maxY } = this.calculateBounds();

        // Ensure the position is within the bounds
        const nextX = Math.min(Math.max(prevState.pos.x, minX), maxX);
        const nextY = Math.min(Math.max(prevState.pos.y, minY), maxY);

        return {
          scale: nextScale,
          pos: { x: nextX, y: nextY }
        };
      }, () => {
          // Save setting
          this.saveSetting();
      });
    }
  };

  handleTouchStart = (event) => {
    if (this.props.enable) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];

      if (touch1 && touch2) {
        // Start tracking touch events for pinch zooming
        event.preventDefault();
        const distance = Math.hypot(
          touch2.pageX - touch1.pageX,
          touch2.pageY - touch1.pageY
        );
        this.lastDistance = distance;
        this.lastTouchCenter = {
          x: (touch1.pageX + touch2.pageX) / 2,
          y: (touch1.pageY + touch2.pageY) / 2
        };
      } else if (touch1 && !touch2) {
        // Start dragging the video element on touch start
        this.isDragging = 'touch';
        this.setState({ lastPos: { x: touch1.pageX, y: touch1.pageY } });
      }
    }
  };

  handleTouchMove = (event) => {
    if (this.props.enable) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
    
      if (touch1 && touch2) {
        // Handle pinch zooming and panning based on touch events
        event.preventDefault();
        const touchCenter = {
          x: (touch1.pageX + touch2.pageX) / 2,
          y: (touch1.pageY + touch2.pageY) / 2
        };
        const distance = Math.hypot(
          touch2.pageX - touch1.pageX,
          touch2.pageY - touch1.pageY
        );
        const scale = (distance / this.lastDistance) * this.state.scale;
    
        this.setState((prevState) => {
          const { pos } = prevState;
    
          const deltaX = touchCenter.x - this.lastTouchCenter.x;
          const deltaY = touchCenter.y - this.lastTouchCenter.y;
          const nextX = pos.x + deltaX / scale;
          const nextY = pos.y + deltaY / scale;
          const { minX, maxX, minY, maxY } = this.calculateBounds();

          // Ensure the scale is within the minimum and maximum limits
          const nextScale = Math.min(Math.max(scale, this.MIN_SCALE), this.MAX_SCALE);

          // Ensure the position is within the bounds
          const nextPosX = Math.min(Math.max(nextX, minX), maxX);
          const nextPosY = Math.min(Math.max(nextY, minY), maxY);
    
          this.lastDistance = distance;
          this.lastTouchCenter = touchCenter;
    
          return {
            scale: nextScale,
            pos: {
              x: nextPosX,
              y: nextPosY
            }
          };
        });
      } else if (touch1 && this.isDragging === 'touch') {

        // Handle single touch dragging for panning
        const dx = touch1.pageX - this.state.lastPos.x;
        const dy = touch1.pageY - this.state.lastPos.y;
    
        this.setState((prevState) => {
          const { pos } = prevState;
          const { minX, maxX, minY, maxY } = this.calculateBounds();
    
          // Calculate the new position within the bounds
          const nextX = Math.min(Math.max(pos.x + dx, minX), maxX);
          const nextY = Math.min(Math.max(pos.y + dy, minY), maxY);
    
          return {
            pos: {
              x: nextX,
              y: nextY
            },
            lastPos: { x: touch1.pageX, y: touch1.pageY }
          };
        });
      }
    }
  };
  

  handleTouchEnd = () => {
    if (this.props.enable) {
      // Reset touch-related variables when touch ends
      this.lastDistance = 0;
      this.lastTouchCenter = { x: 0, y: 0 };
      // Save setting
      this.saveSetting();
    }
  };

  render() {
    const { scale, pos, width, height } = this.state;

    // Apply scale and position to the video element
    const style = {
      left: `${pos.x}px`,
      top: `${pos.y}px`,
      width: `${width}px`,
      height: `${height}px`,
      transform: `scale(${scale})`,
      transformOrigin: 'center center',
      touchAction: 'none'
    };

    return (
        <div ref={this.videoRef} className="video-transform" style={style}>
          {this.props.children}
        </div>
    );
  }
}

export default VideoTransform;
