import axios from "axios";
import InstallationHelper from "./InstallationHelper";
import UserHelper from "./UserHelper";

const ApiHelper = {
  Host: localStorage.getItem("host"),
  BasePath: localStorage.getItem("host") + "/api",
  LoginHost: 'https://login.habbie.net',
  //LoginHost: 'https://habbie-login.test',
  //LoginHost: "https://habbie-login.strativ-support.se",
  // LoginHost: "https://login.stage.habbie.net",

  /**
   * Init the API depending on user's API host
   * @param {Manual API host} value
   */
  Init: function (value) {
    console.log("Init API...");

    // Update host in storage
    if (value) {
      localStorage.setItem("host", value);
    }

    let host = value ?? localStorage.getItem("host");

    this.Host = host;
    this.BasePath = host + "/api";

    console.log("Init API with host:", host);
  },

  /**
   * Function for migrating 1.0.X -> 1.1.0
   * where the external login service is introduced.
   * Without this the app has no saved host and will crash on API calls.
   */
  MigrateLoginService: function () {
    return new Promise((resolve) => {
      // If there is a host we have no problem
      if (ApiHelper.Host) {
        resolve();
      }
      // Else we will re-login
      else {
        console.log("We have no host...");

        let admin_token = localStorage.getItem("admin_token");
        let code = localStorage.getItem("user_id");

        if (admin_token && code) {
          // Re-login with saved code and admin token
          UserHelper.login(code, admin_token)
            .then((res) => {
              // Receive host
              let host = res.data.data.server_url;
              console.log("Logged in again, received host:", host);
              // Save host in local storage
              localStorage.setItem("host", host);
              this.Host = host;
              this.BasePath = host + "/api";

              resolve();
            })
            .catch((error) => {
              // If this was not possible,
              // logout user or the app will crash
              console.log("Could not log in again, logging out:", error);
              UserHelper.logout();
              UserHelper.logoutAdmin();

              resolve();
            });
        } else {
          console.log("We have no token and code! Logging out...");
          UserHelper.logout();
          UserHelper.logoutAdmin();
          resolve();
        }
      }
    });
  },

  /**
   * Get request to the API
   * @param {Endpoint path} path
   * @param {Optionally send token manually} token
   */
  Get: async function (path, token) {
    await ApiHelper.MigrateLoginService();

    return new Promise((resolve, reject) => {
      axios
        .get(this.BasePath + path, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              token
                ? token
                : localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  /**
   * Post request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Post: async function (path, data) {
    await ApiHelper.MigrateLoginService();

    return new Promise((resolve, reject) => {
      axios
        .post(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },

  /**
   * Put request to the API
   * @param {Endpoint path} path
   * @param {Data to send} data
   */
  Put: async function (path, data) {
    await ApiHelper.MigrateLoginService();

    return new Promise((resolve, reject) => {
      axios
        .put(this.BasePath + path, data, {
          "content-type": "application/json",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("admin_token") === "installation"
                ? InstallationHelper.token.get()
                : UserHelper.token.get()
            }`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
};

export default ApiHelper;
